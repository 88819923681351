
import {DOM, inject, noView, bindable, bindingMode, LogManager, ComponentCreated, ComponentBind, ComponentAttached, ComponentDetached, ComponentUnbind, View} from 'aurelia-framework';
import {Logger} from "aurelia-logging";
import {PLATFORM} from "aurelia-pal";

@inject(DOM.Element)
class ErynHeaderCustomAttribute implements ComponentCreated, ComponentBind, ComponentAttached, ComponentDetached, ComponentUnbind {
    private logger:Logger = LogManager.getLogger('components');

    private colorToggler:NodeListOf<HTMLElement>;
    private navContent:NodeListOf<HTMLElement>;
    private navAction:NodeListOf<HTMLElement>;
    private navToggle:NodeListOf<HTMLElement>;

    public constructor(private element:HTMLElement) {
        this.logger.debug('Constructor');
    }

    public created(owningView: View, myView: View): void {
        this.logger.debug('Created');
    }

    public bind(bindingContext: any, overrideContext: any): void {
        this.logger.debug('Bind');
    }

    public attached(): void {
        this.logger.debug('Attached');
        this.colorToggler = this.element.querySelectorAll('.toggle-color');
        this.navContent = this.element.querySelectorAll('.nav-content');
        this.navAction = this.element.querySelectorAll('.nav-action');
        this.navToggle = this.element.querySelectorAll('.nav-toggle');
        DOM.addEventListener('scroll', this.onScroll, false);
        DOM.addEventListener('click', this.onClick, false);
    }

    public detached(): void {
        this.logger.debug('Detached');
        DOM.removeEventListener('scroll', this.onScroll, false);
        DOM.removeEventListener('click', this.onClick, false);
    }

    public unbind(): void {
        this.logger.debug('Unbind');
    }

    private onScroll = (event:Event) => {
        const scrollPosition = PLATFORM.global.scrollY;
        if (scrollPosition > 10) {
            this.colorToggler.forEach((element:HTMLElement) => {
                element.classList.add('text-gray-800');
                element.classList.remove('text-white');
            });
            this.navAction.forEach((element:HTMLElement) => {
                element.classList.remove('bg-white');
                element.classList.add('gradient');
                element.classList.remove('text-gray-800');
                element.classList.add('text-white');
            });
            this.navContent.forEach((element:HTMLElement) => {
                element.classList.add('bg-white');
                element.classList.remove('bg-gray-100');
            });
            this.element.classList.add('bg-white');
            this.element.classList.add('shadow');
        } else {
            this.colorToggler.forEach((element:HTMLElement) => {
                element.classList.add('text-white');
                element.classList.remove('text-gray-800');
            });
            this.navAction.forEach((element:HTMLElement) => {
                element.classList.add('bg-white');
                element.classList.remove('gradient');
                element.classList.add('text-gray-800');
                element.classList.remove('text-white');
            });
            this.navContent.forEach((element:HTMLElement) => {
                element.classList.remove('bg-white');
                element.classList.add('bg-gray-100');
            });
            this.element.classList.remove('bg-white');
            this.element.classList.remove('shadow');
        }
    };

    private onClick = (event:Event) => {
        const targetClick = event.target as HTMLElement;
        if (targetClick.closest('.nav-content') === null) {
            // not in menu
            this.logger.debug('Not in menu');
            const link = targetClick.closest('.nav-toggle');
            if (link !== null) {
                // clicked link
                this.logger.debug('Clicked link');
                this.navContent.forEach((element) => {
                    if (element.classList.contains('hidden')) {
                        element.classList.remove('hidden');
                    } else {
                        element.classList.add('hidden');
                    }
                });
            } else {
                this.logger.debug('Clicked outside link');
                this.navContent.forEach((element) => {
                    element.classList.add('hidden');
                });
            }
        } else {
            // in menu
        }
    };
}

export {ErynHeaderCustomAttribute}
